// hooks
import { lazy, useState } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import CheckAuth from "./context/CheckAuth";
import SuspensedView from "./components/SuspensedView";

// lazy load
const LoginPage = lazy(() => import("./pages/Login"));
const ProjectsPage = lazy(() => import("./pages/Projects"));
const ProjectPage = lazy(() => import("./pages/Project"));

const firebaseConfig = {
  apiKey: "AIzaSyDSGdMiXi0wkUK0D2Yb8MHQQDTgLtM8_cI",
  authDomain: "i18n-dashboard.firebaseapp.com",
  projectId: "i18n-dashboard",
  storageBucket: "i18n-dashboard.appspot.com",
  messagingSenderId: "847514722262",
  appId: "1:847514722262:web:f6104de0d7f2eb288a0e77",
  measurementId: "G-R06PZ7T0G0",
};
firebase.initializeApp(firebaseConfig);

function App() {
  const [user, setUser] = useState(null);
  const [projectsList, setProjectsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(true);
    } else {
      setUser(false);
    }
  });

  function Logout() {
    signOut(auth)
      .then(() => {
        setUser(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <CheckAuth.Provider
      value={{
        user,
        setUser,
        Logout,
        projectsList,
        setProjectsList,
        loading,
        setLoading,
      }}
    >
      <SuspensedView>
        <Router>
          <Switch>
            {!user && user !== null ? (
              <Route to="/auth">
                <SuspensedView>
                  <LoginPage />
                </SuspensedView>
              </Route>
            ) : (
              <Redirect from="/auth" to="/" />
            )}
            <Route path="/projects/:project_id">
              <SuspensedView>
                <ProjectPage />
              </SuspensedView>
            </Route>
            <Route path="/">
              <SuspensedView>
                <ProjectsPage />
              </SuspensedView>
            </Route>
            <Route path="/auth">
              <SuspensedView>
                <LoginPage />
              </SuspensedView>
            </Route>
          </Switch>
        </Router>
      </SuspensedView>
    </CheckAuth.Provider>
  );
}

export default App;
